<template>
  <vca-card>
    <h2>{{ $t("faqs.general.title") }}</h2>
    <p v-html="$t('faqs.general.description')" />
    <br />
    <General />
  </vca-card>
</template>
<script>
import General from "@/components/faqs/FaqsGeneral";
export default {
  name: "FaqsGeneral",
  components: { General },
};
</script>
