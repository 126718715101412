<template>
  <div class="vca-column">
    <vca-accordion :header="$t('faqs.general.vca.header')">
      <div slot="body" v-html="$t('faqs.general.vca.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.general.age.header')">
      <div slot="body" v-html="$t('faqs.general.age.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.general.pool.header')">
      <div slot="body" v-html="$t('faqs.general.pool.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.general.actions.header')">
      <div slot="body" v-html="$t('faqs.general.actions.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.general.crew.header')">
      <div slot="body" v-html="$t('faqs.general.crew.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.general.volunteers.header')">
      <div slot="body" v-html="$t('faqs.general.volunteers.body')" />
    </vca-accordion>
    <vca-accordion :header="$t('faqs.general.action.header')">
      <div slot="body" v-html="$t('faqs.general.action.body')" />
    </vca-accordion>
  </div>
</template>
<script>
export default {
  name: "FaqsGeneral",
};
</script>
